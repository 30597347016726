import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { WWWeaponEntry } from '../../../modules/ww/weapons/ww-weapon';

const WWGuidesBatt: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Pioneer Podcast</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_bp.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Pioneer Podcast</h1>
          <h2>
            All Pioneer Podcast available in Wuthering Waves - learn what awaits
            you in the game!
          </h2>
          <p>
            Last updated: <strong>17/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Pioneer Podcast" />
        <StaticImage
          src="../../../images/ww/generic/system_5.webp"
          alt="Guide"
        />
        <p>
          <strong>Pioneer Podcast is the battle pass of Wuthering Waves</strong>
          . It has a free and premium (that can be unlocked with real money)
          path. Players can get rewards from these paths by completing daily,
          weekly, and periodical missions. Pioneer Podcast resets periodically
          (most likely every 6 weeks).
        </p>
        <p>
          The price of the{' '}
          <strong>
            Premium version (called Insider Channel) was set at $10
          </strong>
          , and you also were able to buy an advanced version that unlocked some
          of the rewards early for $20. Furthermore, the Insider Channel comes
          with a <strong className="ww-color-4">4★</strong>{' '}
          <strong>weapon selector</strong> and those weapons can only be
          obtained from it (so it's the same as in Honkai: Star Rail).
        </p>
        <p>Here's the list of available weapons:</p>
        <div className="ww-weapons-container">
          <div>
            <WWWeaponEntry name="Thunderbolt" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Augment" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Stonard" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Lumingloss" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Autumntrace" level={[1]} mode="icon" />
          </div>
        </div>
        <SectionHeader title="Best Battle Pass Weapon" />
        <p>Coming soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesBatt;

export const Head: React.FC = () => (
  <Seo
    title="Pioneer Podcast | Wuthering Waves | Prydwen Institute"
    description="All Pioneer Podcast available in Wuthering Waves - learn what awaits you in the game!"
    game="ww"
  />
);
